import { Component } from '@angular/core';

@Component({
  selector: 'eeg-root',
  template: `
<app-layout [title]="title" [shortTitle]="shortTitle" [siteLogoUrl]="siteLogoUrl"></app-layout>
  `
})
export class AppComponent {
  title = 'Business Sustainability Assessment Tool';
  shortTitle = 'BSA Tool';
  siteLogoUrl = 'assets/img/white-logo.png';
  // mobileSize = '576px'; // xs <576px; sm ≥576px; md ≥768px; lg ≥992px; xl ≥1200px;
}
