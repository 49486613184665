import { Routes } from '@angular/router';

import { MenuItems } from '@identic/layout';
import { HomeComponent } from './home.component';
import { environment } from '../environments';
import { IdenticAppSelectorComponent, IDENTIC_APP_SELECTOR_PATH } from '@identic/identic-app';

const DummyComponentForExternalUrl = HomeComponent;

export const externalUrlResolver = { url: 'externalUrlRedirectResolver' };
// Page options are here and referenced twice below in Routes & menuItems
// E.G. appointments: { text: 'Appointments', icon: 'people', path: 'appointments', routerLink: '/appointments/list', roles: 'SMS.Admin&&(Unit.EDU||Unit.SLC)' },

const Page = {
  // login: { text: 'Login', icon: 'locked', path: 'login', routerLink: 'login', application: 'eeg', loggedOutOnly: true },
  home: { text: 'Home', icon: 'home', path: 'home', routerLink: 'home', application: 'eeg' },

  applicationSelect: { text: 'Change Application', icon: 'swap_horizontal_circle', path: IDENTIC_APP_SELECTOR_PATH, routerLink: IDENTIC_APP_SELECTOR_PATH, roles: 'Administrators' },

  userAdmin: { text: 'User Admin', icon: 'people', path: 'user-admin', routerLink: 'user-admin', externalUrl: environment.IDSERVER4_USER_ADMIN_URL, roles: 'Administrators' },
  userActivity: { text: 'User Activity', icon: 'build', path: 'user-activity', routerLink: 'user-activity', application: 'eeg', roles: 'Administrators' },

  actionPlanReport: { text: 'Action Plan', icon: 'assignment', path: 'action-plan', routerLink: 'action-plan', application: 'eeg' },
  bsatProductEditor: { text: 'WooCommerce Products', icon: 'assignment', path: 'bsat-products', routerLink: 'bsat-products', application: 'eeg', roles: 'Administrators' },
  checklist: { text: 'Checklist', icon: 'list', path: 'tool', routerLink: 'tool', application: 'eeg' },
  checklistEditor: { text: 'Checklist Editor', icon: 'edit', path: 'checklist', routerLink: 'checklist/edit', roles: 'Administrators' },
  checklistStepEditor: { text: 'Step Editor', icon: 'edit', path: 'checklist/step-edit', routerLink: 'checklist/step-edit', roles: 'Administrators' },

  emailTemplates: { text: 'Email Templates', icon: 'email', path: 'email-template', routerLink: 'email-template/list', roles: 'Administrators||Email.Template.Editor' },
  pageTemplates: { text: 'Template Editor', icon: 'web', path: 'page-template', routerLink: 'page-template/list', roles: 'Administrators||Template.Editor' },

};

export const appRoutes: Routes = [
  { path: Page.home.path, redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent, data: Page.home }, //, canActivate: [AuthGuard], data: { roles: 'admin' }  // rf: https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3

  { path: Page.applicationSelect.path, component: IdenticAppSelectorComponent, data: Page.applicationSelect }, //canActivate: [AuthGuard] },

  { path: Page.actionPlanReport.path, loadChildren: () => import('@action-plan/feature').then(m => m.ReportModule), data: Page.actionPlanReport },
  { path: Page.bsatProductEditor.path, loadChildren: () => import('@woocommerce-products/feature').then(m => m.WooCommerceProductsEditorModule), data: Page.bsatProductEditor, /*canActivate: [AuthGuard]*/},
  { path: Page.checklist.path, loadChildren: () => import('@checklist/feature').then(m => m.ChecklistModule), data: Page.checklist, /*canActivate: [AuthGuard]*/},
  { path: Page.checklistEditor.path, loadChildren: () => import('@checklist/feature').then(m => m.ChecklistModule), data: Page.checklistEditor, /*canActivate: [AuthGuard]*/},
  { path: Page.checklistStepEditor.path, loadChildren: () => import('@checklist/feature').then(m => m.ChecklistModule), data: Page.checklistStepEditor, /*canActivate: [AuthGuard]*/},
  { path: Page.userActivity.path, loadChildren: () => import('@user-activity/feature').then(m => m.UserActivityEditorModule), data: Page.userActivity },

  { path: Page.emailTemplates.path, loadChildren: () => import('@identic/email').then(m => m.EmailTemplateModule), data: Page.emailTemplates, /*canActivate: [AuthGuard]*/ },
  { path: Page.pageTemplates.path, loadChildren: () => import('@identic/templates').then(m => m.TemplateEditorModule), data: Page.pageTemplates, /*canActivate: [AuthGuard]*/},

  { path: Page.userAdmin.path, resolve: externalUrlResolver, component: DummyComponentForExternalUrl, data: Page.userAdmin },

];

export const menuItems: MenuItems = [
  // User profile option
  {
    text: '',             // userDisplayName
    // path?: string;       // Profile path
    // routerLink?: string; // Profile path
    icon: 'person',
    hint: 'User',
    isProfile: true,
  },
  // Page.login,
  Page.home,

  Page.userActivity,
  Page.userAdmin,
  Page.bsatProductEditor,

  Page.checklistEditor,
  Page.checklistStepEditor,
  Page.pageTemplates,
  Page.emailTemplates,
];
