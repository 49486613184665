// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// This file is replaced during build by using the `fileReplacements` array.
// `ng build -c staging-sms` replaces `environment.ts` with `sms/environment.staging.ts`.
// `ng build -c staging` replaces `environment.ts` with `environment.staging.ts`. *** Identic version
// The list of file replacements can be found in `angular.json`.
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --staging` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

// import { LogLevel } from "angular-auth-oidc-client";

export const environment = {
  production: false,

  ADMIN_ROLE: 'Administrators',
  ANON_TEMPLATE_API_URL: 'https://staging.ecoefficiencygroup.com.au/api/v1.0/Template',  // For anon-templates
  API_BASE_URL: 'https://staging.ecoefficiencygroup.com.au/api/v1.0',
  APP_NAME: 'EEG',
  // AUTH_LOG_LEVEL: LogLevel.Debug,
  IDSVR4_API_SCOPE: 'EEG.API.Test',
  IDSVR4_API_CLIENT: 'EEG.UI.Test',
  APP_LICENCE_ID: '0aca873c-c85b-4826-94b3-ef2e65208668',
  // ROUTE_DEBUG: true,
  SITE_LOGO: 'img/white-logo.png',
  SITE_SHORT_TITLE: 'BSA Tool',
  SITE_TITLE: 'Business Sustainability Assessment Tool',
  SSO_SERVER_URL: 'https://login.ecoefficiencygroup.com.au',
  // STORAGE: 'local',  // 'local' | 'session' | 'cookie';  // defaults to 'local'
  VIRTUAL_DIR: '/',

  EXIT_TOOL_REDIRECT_URL: 'https://www.ecoefficiencygroup.com.au/',
  PURCHASE_BSAT_REPORT_URL: 'https://www.ecoefficiencygroup.com.au/bsat-packages/',
  // PURCHASE_BSAT_REPORT_URL: 'https://sandbox.lucidcentral.org/wordpress/product/bsat-report/',
  IDSERVER4_USER_ADMIN_URL: 'https://login.ecoefficiencygroup.com.au/useradmin',
  Api: {
    IdenticApp: 'Licence/IdenticProduct'
  }
};
