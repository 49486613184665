import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppAuthService } from '@identic/auth';

@Component({
  selector: 'eeg-home',
  template: `
  <mat-card class="container">
    <mat-card-content>
      <template-display [application]="(routeData$ | async)!['application']" templateName="HomePage" (visible)="templateIsReady = true"></template-display>
    </mat-card-content>

    <mat-card-actions>
      <button *ngIf="templateIsReady" mat-raised-button color="primary" title="{{(authService.isLoggedIn$|async) ? 'Begin' : 'Register/Login'}}" (click)="goToChecklist()">
        <mat-icon>playlist_play</mat-icon>
        {{(authService.isLoggedIn$|async) ? 'Begin' : 'Register/Login'}}
      </button>
    </mat-card-actions>
  </mat-card>
  `,
  styles: [`
  .step-img {
      padding: 0 20px 0 20px;
    }
 `]
})
export class HomeComponent {
  routeData$ = this.route.data;
  templateIsReady: boolean = false;

  constructor(public route: ActivatedRoute, private router: Router, public authService: AppAuthService) { }

  // isLoggedIn$()
  goToChecklist(): void {
    this.router.navigateByUrl('tool');
  }

}
// console.log(`%c HomeComponent::ngOnInit`, 'background:yellow');
